import React from "react";

const IconNewsCalculatorV2 = ({ width = 24, height = 24, color }) => (
  <svg
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 22 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4092 0H0.590763C0.434083 0 0.28382 0.0621051 0.17303 0.172653C0.0622409 0.283201 0 0.433136 0 0.589474L0 27.4105C0 27.5669 0.0622409 27.7168 0.17303 27.8273C0.28382 27.9379 0.434083 28 0.590763 28H21.4092C21.5659 28 21.7162 27.9379 21.827 27.8273C21.9378 27.7168 22 27.5669 22 27.4105V0.589474C22 0.433136 21.9378 0.283201 21.827 0.172653C21.7162 0.0621051 21.5659 0 21.4092 0V0ZM20.8185 26.8211H1.18153V1.17895H20.8185V26.8211Z"
      fill={color}
    />
    <path
      d="M4.79694 8.31155H17.203C17.3596 8.31155 17.5099 8.24944 17.6207 8.1389C17.7315 8.02835 17.7937 7.87841 17.7937 7.72208V4.3385C17.7937 4.18216 17.7315 4.03222 17.6207 3.92168C17.5099 3.81113 17.3596 3.74902 17.203 3.74902H4.79694C4.64026 3.74902 4.49 3.81113 4.37921 3.92168C4.26842 4.03222 4.20618 4.18216 4.20618 4.3385V7.72208C4.20618 7.87841 4.26842 8.02835 4.37921 8.1389C4.49 8.24944 4.64026 8.31155 4.79694 8.31155ZM5.3877 4.92797H16.6122V7.1326H5.3877V4.92797Z"
      fill={color}
    />
    <path
      d="M5.57679 13.5107C6.10534 13.5107 6.53382 13.0832 6.53382 12.5558C6.53382 12.0284 6.10534 11.6008 5.57679 11.6008C5.04823 11.6008 4.61975 12.0284 4.61975 12.5558C4.61975 13.0832 5.04823 13.5107 5.57679 13.5107Z"
      fill={color}
    />
    <path
      d="M9.19227 13.5107C9.72082 13.5107 10.1493 13.0832 10.1493 12.5558C10.1493 12.0284 9.72082 11.6008 9.19227 11.6008C8.66371 11.6008 8.23523 12.0284 8.23523 12.5558C8.23523 13.0832 8.66371 13.5107 9.19227 13.5107Z"
      fill={color}
    />
    <path
      d="M12.8077 13.5107C13.3363 13.5107 13.7648 13.0832 13.7648 12.5558C13.7648 12.0284 13.3363 11.6008 12.8077 11.6008C12.2792 11.6008 11.8507 12.0284 11.8507 12.5558C11.8507 13.0832 12.2792 13.5107 12.8077 13.5107Z"
      fill={color}
    />
    <path
      d="M16.4351 13.5107C16.9636 13.5107 17.3921 13.0832 17.3921 12.5558C17.3921 12.0284 16.9636 11.6008 16.4351 11.6008C15.9065 11.6008 15.478 12.0284 15.478 12.5558C15.478 13.0832 15.9065 13.5107 16.4351 13.5107Z"
      fill={color}
    />
    <path
      d="M5.57676 18.0025C6.11184 18.0025 6.54561 17.5697 6.54561 17.0358C6.54561 16.5019 6.11184 16.069 5.57676 16.069C5.04168 16.069 4.60791 16.5019 4.60791 17.0358C4.60791 17.5697 5.04168 18.0025 5.57676 18.0025Z"
      fill={color}
    />
    <path
      d="M9.19224 18.0025C9.72732 18.0025 10.1611 17.5697 10.1611 17.0358C10.1611 16.5019 9.72732 16.069 9.19224 16.069C8.65716 16.069 8.22339 16.5019 8.22339 17.0358C8.22339 17.5697 8.65716 18.0025 9.19224 18.0025Z"
      fill={color}
    />
    <path
      d="M12.8077 18.0025C13.3428 18.0025 13.7766 17.5697 13.7766 17.0358C13.7766 16.5019 13.3428 16.069 12.8077 16.069C12.2726 16.069 11.8389 16.5019 11.8389 17.0358C11.8389 17.5697 12.2726 18.0025 12.8077 18.0025Z"
      fill={color}
    />
    <path
      d="M16.435 18.0025C16.9701 18.0025 17.4039 17.5697 17.4039 17.0358C17.4039 16.5019 16.9701 16.069 16.435 16.069C15.9 16.069 15.4662 16.5019 15.4662 17.0358C15.4662 17.5697 15.9 18.0025 16.435 18.0025Z"
      fill={color}
    />
    <path
      d="M5.57676 22.4825C6.11184 22.4825 6.54561 22.0497 6.54561 21.5158C6.54561 20.9819 6.11184 20.5491 5.57676 20.5491C5.04168 20.5491 4.60791 20.9819 4.60791 21.5158C4.60791 22.0497 5.04168 22.4825 5.57676 22.4825Z"
      fill={color}
    />
    <path
      d="M9.19224 22.4825C9.72732 22.4825 10.1611 22.0497 10.1611 21.5158C10.1611 20.9819 9.72732 20.5491 9.19224 20.5491C8.65716 20.5491 8.22339 20.9819 8.22339 21.5158C8.22339 22.0497 8.65716 22.4825 9.19224 22.4825Z"
      fill={color}
    />
    <path
      d="M12.8077 22.4825C13.3428 22.4825 13.7766 22.0497 13.7766 21.5158C13.7766 20.9819 13.3428 20.5491 12.8077 20.5491C12.2726 20.5491 11.8389 20.9819 11.8389 21.5158C11.8389 22.0497 12.2726 22.4825 12.8077 22.4825Z"
      fill={color}
    />
    <path
      d="M16.435 22.4825C16.9701 22.4825 17.4039 22.0497 17.4039 21.5158C17.4039 20.9819 16.9701 20.5491 16.435 20.5491C15.9 20.5491 15.4662 20.9819 15.4662 21.5158C15.4662 22.0497 15.9 22.4825 16.435 22.4825Z"
      fill={color}
    />
  </svg>
);

export default IconNewsCalculatorV2;
