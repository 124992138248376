import React, { useContext, Fragment } from "react";
import cx from "classnames";
import { infoContext } from "../context/infoProvider";
import { Box } from "../style/Grid";
import { Text } from "../style/Text";
import { MaintenanceBox } from "../style/Grid/MaintenanceBox";
import { theme } from "../style/theme";

const Maintenance = ({ padding, margin, sticky }) => {
  const { maintenance } = useContext(infoContext);
  const isShow = maintenance?.maintenance_in_progress;
  const isSticky = !!sticky;

  return (
    <Fragment>
      {isShow && (
        <MaintenanceBox padding={padding ? padding : "100px"} alignItems={"center"} style={{ width: "100%" }}>
          <div className={cx({ "sticky-wrapper": isSticky })}>
            <Text
              textAlign="center"
              fontSize={["12px", null, null, "15px"]}
              maxWidth={"1200px"}
              margin={["0", null, null, "0 auto"]}
              color={theme.colors.white[100]}
              dangerouslySetInnerHTML={{
                __html: maintenance.maintenance_end_time_text,
              }}
            />
          </div>
        </MaintenanceBox>
      )}
    </Fragment>
  );
};

export default Maintenance;
