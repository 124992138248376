import React from "react";
import PropTypes from "prop-types";

import { NEWS_BODY_LEAD, NEWS_SIDEBAR_LEAD, CONTRACT_SOURCE_ID_NEWS } from "../../constants";
import { Box, Flex } from "../../style/Grid";
import ContactForm from "../contactForm";

const NewsPhoneLeadForm = ({ variant }) => {
  if (variant === NEWS_BODY_LEAD)
    return (
      <Box display={["block", null, null, "none"]} maxWidth="340px" m={"60px auto"} boxShadow="newsSidebar" py={4} px={35}>
        <ContactForm
          leadsource={CONTRACT_SOURCE_ID_NEWS}
          enableCallbackTime={false}
          enableEmail={false}
          type={"NEWS"}
          commodity={null}
          analytics={{
            text: "Zavolejte mi",
            type: "Lead",
            section: "Article",
            sourceId: CONTRACT_SOURCE_ID_NEWS,
          }}
        />
      </Box>
    );

  if (variant === NEWS_SIDEBAR_LEAD)
    return (
      <Flex display={["none", null, null, "flex"]}>
        <ContactForm
          leadsource={CONTRACT_SOURCE_ID_NEWS}
          enableCallbackTime={false}
          enableEmail={false}
          type={"NEWS"}
          commodity={null}
          analytics={{
            name: null,
            text: "Zavolejte mi",
            type: "Lead",
            section: "Article",
            sourceId: CONTRACT_SOURCE_ID_NEWS,
          }}
        />
      </Flex>
    );
};

NewsPhoneLeadForm.propTypes = {
  variant: PropTypes.string.isRequired,
};

export default NewsPhoneLeadForm;
