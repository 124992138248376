import styled from "styled-components";
import { Flex } from "../Grid";

const MaintenanceBox = styled(Flex)`
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(7, 46, 12, 0.9);
  text-align: center;
  display: flex;
  line-height: 2em;
  font-size: 18px;
  justify-content: center;
  align-items: center;

    @media screen and (max-width: ${props => props.theme.breakpoints["xs"]}) {
      align-items: flex-start;
      padding-top: 150px;
    }
}

  p {
    line-height: 1.5;
    max-width: 980px;
    padding: 0 15px;
  }
  div.sticky-wrapper {
    @media screen and (max-width: ${props => props.theme.breakpoints["xs"]}) {
      position: sticky;
      top: 50vh;
      transform: translateY(-50%);
    }
  }
`;

MaintenanceBox.displayName = "MaintenanceBox";

export { MaintenanceBox };
