import React from "react";

import { Box } from "../../style/Grid";
import { Text } from "../../style/Text";
import { Heading } from "../../style/Heading";
import { CONTENT, MND_LINKS } from "../../constants";

const Reviews = () => {
  return (
    <Box bg="grey.lighter" px="16px" py="20px" mb={20}>
      <Text as="p" color="green.dark" fontWeight={700} mb={3}>
        RECENZE
      </Text>

      <Box px={[0, null, 20]}>
        <Text fontSize={14}>
          Nejlepší referencí jsou pro&nbsp;nás pozitivní recenze a&nbsp;reakce našich zákazníků, proto{" "}
          uvádíme jejich příklady z&nbsp;našeho{" "}
          <Text as="a" color="green.dark" href={`mailto:${CONTENT.email.href}`}>
            Facebooku
          </Text>
          . V&nbsp;případě, že&nbsp;potřebujete s&nbsp;něčím pomoci, kontaktujte nás na&nbsp;emailu{" "}
          <Text as="a" color="green.dark" href={`mailto:${CONTENT.email.href}`}>
            {CONTENT.email.formatted}
          </Text>
          .
        </Text>

        <Heading as="h4" textAlign="center" color="green.dark" mt={10} mb={20}>
          Díky tomu jsme již čtvrtým rokem nejpreferovanějším novým dodavatelem energií
        </Heading>

        <Box textAlign="center">
          <Text
            as="a"
            color="grey.dark"
            href={MND_LINKS.review}
            css={{
              textDecoration: "underline",
              ":hover": {
                textDecoration: "none",
              },
            }}
          >
            Všechny recenze
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export { Reviews };
