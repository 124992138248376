import React, { useEffect, useState, Fragment, useContext } from "react";
import { graphql } from "gatsby";
import loadable from "@loadable/component";
import htmlParse, { attributesToProps } from "html-react-parser";

import LayoutMain from "../../components/layoutMain";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Wysiwyg } from "../../style/Wysiwyg";
import { MoreNews } from "../../components/News/MoreNews";
import { Survey } from "../../components/News/Survey";
import { Reviews } from "../../components/News/Reviews";
import { Calc } from "../../components/News/Calc";
import { formattedDate } from "../../helpers";
import { Image } from "../../style/Image";
import BreadcrumbsV2 from "../../components/breadcrumbsV2";
import { Helmet } from "react-helmet";
import NewsCalculator from "../../components/NewsCalculator";
import { MND_LINKS, NEWS_BODY_LEAD, NEWS_SIDEBAR_LEAD } from "../../constants";
import NewsPhoneLeadForm from "../../components/News/NewsPhoneLeadForm";
import { infoContext } from "../../context/infoProvider";

const Share = loadable(() => import("../../components/News/Share"));

const NewsDetail = props => {
  let initialData = props.data.news;
  if (props.pageContext.fakeData) {
    initialData = props.pageContext.fakeData.news;
  }

  const { leadMaintenance } = useContext(infoContext);

  const [newsData, setNewsData] = useState(initialData);

  useEffect(() => {
    if (typeof document !== "undefined" && typeof URLSearchParams !== "undefined") {
      const newsId = new URLSearchParams(document.location.search).get("newsId");
      if (!newsId) {
        return;
      }

      (async () => {
        const newsResult = await fetch(`/api/news/${newsId}`);
        setNewsData(await newsResult.json());
      })();
    }
  }, []);

  const {
    name,
    releaseDate: { date },
    perex,
    slug,
    content,
    calculatorType,
    descriptionImage,
  } = newsData;
  const { nextArticles } = props.pageContext;

  const contentHasPhoneLeadActivated = content => {
    return content && content.includes("__PHONE_LEAD_FORM__");
  };

  return (
    <LayoutMain>
      <Helmet>
        <title>{name} - MND</title>
        <meta name="description" content={perex} />
        <link rel="canonical" href={`${process.env.GATSBY_PUBLIC_WEB_URL || "https://www.mnd.cz"}/${MND_LINKS.news}/${slug}`} />

        <meta property="og:url" content={`${process.env.GATSBY_PUBLIC_WEB_URL || "https://www.mnd.cz"}/${MND_LINKS.news}/${slug}`} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`${name} - MND`} />
        <meta property="og:description" content={perex} />
        <meta property="og:image" content={`${process.env.GATSBY_PUBLIC_WEB_URL || "https://www.mnd.cz"}${descriptionImage}`} />
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Article', categories: 'News' });
        `}</script>
      </Helmet>

      <Wrapper pb={4}>
        <Container>
          <BreadcrumbsV2
            pages={[
              {
                name: "Novinky",
                link: "/novinky",
              },
              {
                name: name,
                link: slug,
              },
            ]}
            color={"grey.dark"}
          />
        </Container>

        <Container>
          <Flex pl={[0, null, null, null, 190]} mb={5} width={1} flexDirection="column" alignItems={["flex-start", null, null, "center"]}>
            <Box maxWidth={["660px"]} alignSelf={[null, null, null, "flex-start"]} mx={["auto", null, null, 0]}>
              <Heading as="h1" fontSize={[7, null, 9]} color="green.dark" mb={30}>
                {name}
              </Heading>
              <Text as="p" fontSize={1} mb={3} color="grey.dark" fontWeight="semiBold">
                {formattedDate(date).toUpperCase()}
              </Text>
            </Box>

            <Flex position="relative" maxWidth={["660px", null, null, "100%", "none"]} mx={["auto", null, null, 0]}>
              <Box maxWidth={["660px"]}>
                <Image src={descriptionImage} mb={4} width="100%" mx="auto" display="block" />
                {perex && (
                  <Text as={"p"} fontSize={20} mb={4} lineHeight={3}>
                    {perex}
                  </Text>
                )}

                {content && (
                  <Wysiwyg>
                    {htmlParse(content, {
                      replace: function(domNode) {
                        if (domNode.name === "iframe") {
                          let props = attributesToProps(domNode.attribs);
                          props["data-cookieblock-src"] = props.src;
                          props["data-cookieconsent"] = "marketing";

                          return <iframe {...props} />;
                        }

                        if (domNode.children && domNode.children[0] && domNode.children[0].data && domNode.name === "p") {
                          if (domNode.children[0].data.match(/__ANKETA__([a-z0-9]+)/)) {
                            const [, surveyId] = domNode.children[0].data.match(/__ANKETA__([a-z0-9-]+)/);
                            return <Survey surveyId={surveyId} />;
                          }
                          if (domNode.children[0].data.match(/__PHONE_LEAD_FORM__/)) {
                            if (leadMaintenance?.lead_maintenance_in_progress) return <Fragment />;
                            return <NewsPhoneLeadForm variant={NEWS_BODY_LEAD} />;
                          }

                          if (domNode.children[0].data.match(/__RECENZE__/)) {
                            return <Reviews />;
                          }

                          if (domNode.children[0].data.match(/__NEWS_CONTENT_CALCULATOR__/)) {
                            if (leadMaintenance?.lead_maintenance_in_progress) return <Fragment />;
                            return <NewsCalculator />;
                          }
                        }
                      },
                    })}
                  </Wysiwyg>
                )}
              </Box>

              {!leadMaintenance?.lead_maintenance_in_progress && (
                <Flex
                  display={["none", null, null, "block"]}
                  alignSelf="flex-start"
                  position="sticky"
                  top={80}
                  ml={30}
                  maxWidth={["285px"]}
                  minWidth={["285px"]}
                >
                  <Box>
                    {contentHasPhoneLeadActivated(content) && (
                      <Box position={"relative"}>
                        <Box display={["none", null, null, "block"]} px={24} py={4} boxShadow="newsSidebar">
                          <NewsPhoneLeadForm variant={NEWS_SIDEBAR_LEAD} />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Container>

        {calculatorType && !leadMaintenance?.lead_maintenance_in_progress && (
          <Fragment>
            <Box position="relative">
              <Calc leadsource={props.data.blockData?.leadsource || 0} initialText={""} />
            </Box>
          </Fragment>
        )}
        <Share name={name} url={slug} />

        {nextArticles.length > 0 && (
          <Container>
            <Heading as={"h3"} fontSize={[7, null, 9]} color="green.dark" mb={4}>
              Další články
            </Heading>
            <MoreNews articles={nextArticles} />
          </Container>
        )}
      </Wrapper>
    </LayoutMain>
  );
};

export default NewsDetail;

export const pageQuery = graphql`
  query GET_NEW($slug: String) {
    news(slug: { eq: $slug }) {
      name
      releaseDate {
        date
      }
      perex
      content
      descriptionImage
      slug
      promolink
      calculatorType
    }
    blockData {
      leadsource
    }
  }
`;
