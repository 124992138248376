import styled from "styled-components";
import { Box } from "../Grid";

const Wysiwyg = styled(Box)`
  p {
    margin: 0 0 0.7em;
  }
  ,
  a {
    color: ${props => props.theme.colors.green.dark};
  }
  ,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 1.5em;
    color: ${props => props.theme.colors.green.dark};
  }

  h1 {
    font-size: ${props => props.theme.fontSizes[7]};
  }
  h2 {
    font-size: ${props => props.theme.fontSizes[6]};
  }
  h3 {
    font-size: ${props => props.theme.fontSizes[5]};
  }
  h4 {
    font-size: ${props => props.theme.fontSizes[4]};
  }

  img {
    width: 100% !important;
    height: auto !important;
  }

  ul {
    list-style-type: square;
    margin: 1.5em 0 1.5em 1.5em;
    li {
      font-weight: ${props => props.theme.fontWeights.bold};
      color: ${props => props.theme.colors.green.dark};
    }
  }

  blockquote {
    position: relative;
    margin: 1.5em 0;
    padding: 70px 20px 20px;
    background-color: #eef2d6;
    line-height: ${props => props.theme.lineHeights[2]};
    font-style: italic;
    font-weight: ${props => props.theme.fontWeights.bold};
    font-size: ${props => props.theme.fontSizes[6]};
    color: ${props => props.theme.colors.green.dark};
    &:before {
      content: "\\201D";
      position: absolute;
      top: 4px;
      left: 20px;
      font-family: Georgia, serif;
      font-size: 90px;
      color: ${props => props.theme.colors.green.light};
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    h1 {
      font-size: ${props => props.theme.fontSizes[9]};
    }

    h2 {
      font-size: ${props => props.theme.fontSizes[8]};
    }

    h3 {
      font-size: ${props => props.theme.fontSizes[7]};
    }

    h4 {
      font-size: ${props => props.theme.fontSizes[6]};
    }
  }
`;

Wysiwyg.displayName = "Wysiwyg";

Wysiwyg.defaultProps = {};

export { Wysiwyg };
