import styled from "styled-components";

const LoadingIcon = styled.div`
  position: relative;
  width: ${props => props.small ? "100%" : "200px"};
  min-height: ${props => props.small ? "45px" : "115px"};
  margin: ${props => props.small ? "10px auto 10px" : "30px auto 0"};
  background: url("../images/bazmek-loading--green.gif") no-repeat top center;
  background-size: ${props => props.small ? "10%" : "auto"};

  span {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    font-weight: 600;
    color: #70b214;
  }
`;

LoadingIcon.displayName = "LoadingIcon";

LoadingIcon.defaultProps = {};

export default LoadingIcon;
