import styled from "styled-components";

import { Flex } from "../Grid";

const NewsListPromotedStyle = styled(Flex)`
  background-color: blue;
`;

NewsListPromotedStyle.displayName = "NewsListPromotedStyle";

NewsListPromotedStyle.defaultProps = {};

export { NewsListPromotedStyle };
