import styled from "styled-components";

import { Flex } from "../Grid";

const AlertStyle = styled(Flex)`
  position: relative;
  width: 100%;
  flex-direction: column;
  background-color: ${props => props.theme.colors.green.extraLight};
  svg {
    fill: ${props => props.theme.colors.grey.dark};
  }
  & > button {
    position: absolute;
    top: 30px;
    right: 30px;
    &:hover {
      svg {
        fill: ${props => props.theme.colors.black[100]};
      }
    }
  }
`;

AlertStyle.displayName = "AlertStyle";

AlertStyle.defaultProps = {
  p: ["25px 20px", null, null, "35px 50px"],
};

export { AlertStyle };
