import React, { Fragment, useState } from "react";
import { NewsList } from "./NewsList";
import { Box, Flex } from "../../style/Grid";
import { Button } from "../../style/Button";
import { useMediaQuery } from "react-responsive";
import { theme } from "../../style/theme";

const MoreNews = ({ articles, loadMore, showDate, style }) => {
  const isMobileScreen = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  const [visibleNews, setVisibleNews] = useState(isMobileScreen ? 2 : 3);

  if (!articles) return null;

  return (
    <Fragment>
      <Box width={1}>
        <NewsList articles={articles.slice(0, visibleNews)} showDate={showDate} style={style} />
      </Box>

      {loadMore && (
        <Flex justifyContent="center" width={1} mb={6} mt={3}>
          <Button px={4} onClick={() => setVisibleNews(visibleNews + (isMobileScreen ? 2 : 3))}>
            Načíst další
          </Button>
        </Flex>
      )}
    </Fragment>
  );
};

MoreNews.defaultProps = {
  loadMore: true,
  showDate: true,
};

export { MoreNews };
