import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";

import { GasAdvanceDiscountForm } from "../advanceDiscountForm";
import { Container } from "../../style/Grid";
import { theme } from "../../style/theme";
import { PAGE_NEWS } from "../../constants";

const Calc = ({ leadsource, initialText }) => {
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Container my={5}>
          <GasAdvanceDiscountForm leadsource={leadsource} initialText={initialText} showContactForm={true} type={PAGE_NEWS} />
        </Container>
      </ThemeProvider>
    </Fragment>
  );
};

export { Calc };
