import styled from "styled-components";
import { space, color, layout, typography, flexbox, border, variant, position } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-bottom: 3px solid;
  cursor: pointer;
  transition: background-color ${themeGet("times.transition")};
  text-decoration: none;

  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;

  ${space};
  ${color};
  ${layout};
  ${typography};
  ${flexbox};
  ${border};
  ${position};
  svg {
    height: 24px;
    margin-right: 20px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${themeGet("colors.professionalGray")};
    border-color: ${themeGet("colors.darkGrey")};
    &:hover {
      background-color: ${themeGet("colors.professionalGray")};
    }
  }
  ${props =>
    variant({
      variants: {
        primary: {
          backgroundColor: "resourceGreen",
          color: "white.100",
          borderBottomColor: "resourceGreenDarker",
          "&:hover": {
            backgroundColor: "resourceGreenDarker",
          },
          "&:active, &:focus": {
            color: "white.100",
          },
        },
        disabled: {
          cursor: "not-allowed",
          backgroundColor: "professionalGray",
          borderColor: "darkGrey",
          color: "white.100",
          pointerEvents: "none",
        },
        greyActive: {
          borderStyle: "solid",
          backgroundColor: "grey.standard",
          borderColor: "professionalGray",
          color: "exploratoryGreen",
        },
        grey: {
          borderStyle: "solid",
          backgroundColor: "grey.standard",
          borderColor: "professionalGray",
          color: "exploratoryGreen",
          pointerEvents: "none",
        },
        white: {
          backgroundColor: "white.100",
          color: "green.dark",
          borderBottomColor: "#d6d6d6",
          "&:hover": {
            backgroundColor: "#d6d6d6",
          },
          "&:active, &:focus": {
            color: "green.dark",
          },
        },
        border: {
          border: "2px solid #90A032",
          backgroundColor: "#fff",
          color: "resourceGreen",
          "&:hover": {
            borderColor: "exploratoryGreen",
            color: "exploratoryGreen",
          },
          "&:active, &:focus": {
            borderColor: "exploratoryGreen",
            color: "exploratoryGreen",
          },
        },
        greenDark: {
          backgroundColor: "green.dark",
          color: "white.100",
          borderBottomColor: "#205125",
          "&:hover": {
            backgroundColor: "#205125",
          },
          "&:active, &:focus": {
            color: "white.100",
          },
        },
        link: {
          fontSize: 15,
          lineHeight: 1.3,
          backgroundColor: "none",
          border: "none",
          color: "exploratoryGreen",
          textDecoration: "underline",
          height: "auto",
          padding: 0,
          "&:hover": {
            textDecoration: "none",
          },
          "&:active, &:focus": {
            textDecoration: "none",
          },
        },
      },
    })}
`;

Button.displayName = `Button`;

Button.defaultProps = {
  variant: "primary",
  fontSize: "18px",
  fontWeight: "600",
  height: "54px",
};

Button.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
  ...propTypes.border,
};

export { Button };
