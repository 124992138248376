import styled from "styled-components";

import { Flex } from "../Grid";

const NewsListStyle = styled(Flex)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

NewsListStyle.displayName = "NewsListStyle";

NewsListStyle.defaultProps = {};

export { NewsListStyle };
