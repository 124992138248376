import React from "react";
import LoadingIcon from "../style/LoadingIcon";

import { Flex } from "../style/Grid";

const Loading = ({ minHeight = "200px" }) => {
  return (
    <Flex maxWidth="100%" minHeight={minHeight} justifyContent="center" alignItems="center">
      <LoadingIcon>
        <span>Načítání...</span>
      </LoadingIcon>
    </Flex>
  );
};

export default Loading;
