import React, { Fragment, useEffect, useState } from "react";
import { Heading } from "../../style/Heading";
import { Box, Flex } from "../../style/Grid";
import { Text } from "../../style/Text";
import {
  getPoll,
  getPollIds,
  getResults,
  sendPollResponse,
} from "../../api/api";

const Survey = ({ surveyId }) => {
  const [loading, setLoading] = useState(true);
  const [questionConfig, setQuestionConfig] = useState(null);
  const [pollResults, setPollResults] = useState(null);
  const [resultId, setResultId] = useState(null);
  const [postId, setPostId] = useState(null);

  useEffect(
    _ => {
      (async () => {
        const { resultId, postId } = await getPollIds(surveyId);
        let response = await getPoll(surveyId);
        let questionConfig = response && response.pages[0].elements[0];

        if (
          window.localStorage &&
          window.localStorage.getItem(`${surveyId}${questionConfig.name}`)
        ) {
          let results = await getResults(resultId, questionConfig.name);
          setPollResults({
            count: results.ResultCount,
            results: results.QuestionResult,
          });
        }
        setQuestionConfig(questionConfig);
        setResultId(resultId);
        setPostId(postId);
        setLoading(false);
      })();
    },
    [surveyId]
  );

  const handleSendResult = async (name, value) => {
    setLoading(true);

    await sendPollResponse(postId, `{\"${name}\":\"${value}\"}`); // eslint-disable-line
    if (window.localStorage) {
      window.localStorage.setItem(`${surveyId}${name}`, new Date().getTime());
    }

    let results = await getResults(resultId, name);

    setPollResults({
      count: results.ResultCount,
      results: results.QuestionResult,
    });
    setLoading(false);
  };

  return (
    <Fragment>
      <Box bg="grey.lighter" px="16px" py="20px" mb={20}>
        <Text as="p" color="green.light" fontWeight={700} mb={3}>
          ANKETA
        </Text>

        {questionConfig && questionConfig.type === "radiogroup" && (
          <Box px={[0, null, 80]}>
            <Heading as="h4" color="green.dark" fontWeight={400} fontSize={20}>
              {questionConfig.title
                ? questionConfig.title
                : questionConfig.name}
            </Heading>

            {questionConfig.choices.map((choice, index) => {
              const value = typeof choice === "string" ? choice : choice.value;
              const title = typeof choice === "string" ? choice : choice.text;
              const percentage =
                pollResults &&
                pollResults.results &&
                Math.round(
                  pollResults.results[value] * (100 / pollResults.count)
                ); // eslint-disable-line
              const percentageLabel = percentage ? `${percentage} %` : "? %";

              return (
                <Box key={index} mt={3}>
                  <Flex justifyContent="space-between">
                    {!pollResults && !loading && (
                      <Text
                        as="button"
                        color="green.dark"
                        fontSize={18}
                        fontWeight={700}
                        fontStyle="italic"
                        css={{
                          textDecoration: "underline",
                          ":hover": {
                            textDecoration: "none",
                          },
                        }}
                        onClick={() =>
                          handleSendResult(questionConfig.name, value)
                        }
                      >
                        {title}
                      </Text>
                    )}
                    {pollResults && !loading && (
                      <Text
                        as="span"
                        color="green.dark"
                        fontSize={18}
                        fontWeight={700}
                        fontStyle="italic"
                      >
                        {title}
                      </Text>
                    )}

                    <Text as="p" color="green.dark">
                      {loading ? "Načítání..." : percentageLabel}
                    </Text>
                  </Flex>

                  <Box width={1} height={18} bg="#f7f7f8">
                    <Box
                      width={`${percentage}%`}
                      height={18}
                      bg="green.light"
                    />
                  </Box>
                </Box>
              );
            })}

            {pollResults && (
              <Text as="p" color="green.dark" mt={3}>
                Celkem hlasovalo {pollResults.count} čtenářů.
              </Text>
            )}
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export { Survey };
