import React, { useState, useRef } from "react";
import { Box, Flex } from "../style/Grid";
import Electricity from "../style/icons/electricity";
import Gas from "../style/icons/gas";
import { theme } from "../style/theme";
import { Text } from "../style/Text";
import IconNewsCalculatorV2 from "../style/icons/IconNewsCalculatorV2";
import { NEWS_CONTENT_CALCULATOR } from "../constants";
import { GasAdvanceDiscountForm } from "./advanceDiscountForm";
import { ElectricitySliderForm } from "./electricitySliderForm";
import { Heading } from "../style/Heading";

import Maintenance from "../components/Maintenance";

const NewsCalculatorTab = ({ tab, activeTab, setActiveTab, label }) => {
  const isGasActive = activeTab === tab;
  const isGas = tab === "gas";

  return (
    <Box
      as={"button"}
      onClick={() => setActiveTab(tab)}
      position="relative"
      mr={isGas ? 1 : 0}
      ml={!isGas ? 1 : 0}
      textAlign="center"
      maxWidth={200}
      py={20}
      pr={[10, null, 20]}
      pl={[30, null, 20]}
      width={1}
      bg={isGasActive ? theme.colors.grey.lighter : theme.colors.grey.extraLight}
    >
      <Box position="absolute" top={"15px"} left={[isGas ? "15px" : "5px", "10px"]}>
        {tab === "gas" && <Gas color={isGasActive ? theme.colors.resourceGreen : theme.colors.exploratoryGreen} />}
        {tab === "electricity" && <Electricity color={isGasActive ? theme.colors.resourceGreen : theme.colors.exploratoryGreen} />}
      </Box>
      <Text color={isGasActive ? theme.colors.resourceGreen : theme.colors.exploratoryGreen} fontWeight={600} fontSize={20} mb={"0 !important"}>
        {label}
      </Text>
    </Box>
  );
};

const NewsCalculator = () => {
  const [activeTab, setActiveTab] = useState("gas");
  const calculatorRef = useRef();
  return (
    <Box mt={3} mb={4} ref={calculatorRef} position="relative">
      <Box
        as="button"
        onClick={() =>
          calculatorRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          })
        }
        zIndex={3000}
        position="fixed"
        display={["flex", null, null, "none"]}
        alignItems={"center"}
        bottom={"10vh"}
        left={0}
        p={"15px 15px"}
        width="207px"
        borderRadius="0px 64px 64px 0px"
        bg={theme.colors.resourceGreen}
        color="white.100"
        style={{ filter: "drop-shadow(0px 3px 0px #90A032)" }}
      >
        <IconNewsCalculatorV2 width={22} color={theme.colors.white["100"]} />
        <Text mb={"0 !important"} pl={3} fontWeight={600}>
          Spočítat úsporu
        </Text>
      </Box>
      <Maintenance />
      <Heading
        as={"h4"}
        fontSize={["18px", null, "22px"]}
        lineHeight={"28px"}
        fontWeight="700"
        mb={"12px"}
        mt={["19px !important", null, "30px !important"]}
        css={{ textTransform: "uppercase" }}
      >
        Spočítejte si úsporu:
      </Heading>
      <Flex>
        <NewsCalculatorTab tab={"gas"} activeTab={activeTab} setActiveTab={setActiveTab} label="PLYN" />

        <NewsCalculatorTab tab={"electricity"} activeTab={activeTab} setActiveTab={setActiveTab} label="ELEKTŘINA" />
      </Flex>

      <Box bg="grey.lighter" p={["32px 20px 50px", 4]}>
        {activeTab === "gas" && <GasAdvanceDiscountForm type={NEWS_CONTENT_CALCULATOR} />}

        {activeTab === "electricity" && <ElectricitySliderForm type={NEWS_CONTENT_CALCULATOR} />}
      </Box>
    </Box>
  );
};

export default NewsCalculator;
