import React from "react";
import { NewsListItemStyle } from "../../style/News";
import { PAGE_OMNIS } from "../../constants";

const NewsListItem = ({ children, type, style, showDescription }) => {
  if (style === PAGE_OMNIS) {
    return (
      <NewsListItemStyle type={type} width={["100%", "calc(50% - 30px)", null, null]} showDescription={showDescription}>
        {children}
      </NewsListItemStyle>
    );
  }

  return (
    <NewsListItemStyle type={type} showDescription={showDescription}>
      {children}
    </NewsListItemStyle>
  );
};

NewsListItem.defaultProps = {
  showDescription: true,
};

export { NewsListItem };
