import styled from "styled-components";
import { space, layout, border, position, color } from "styled-system";
import propTypes from "@styled-system/prop-types";

const Image = styled.img`
  margin: 0;
  padding: 0;
  ${space};
  ${layout};
  ${border};
  ${position};
  ${color};
`;

Image.displayName = `Image`;

Image.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.border,
  ...propTypes.position,
  ...propTypes.color,
};

export { Image };
